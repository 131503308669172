
import { onUnmounted, defineComponent, onMounted } from "vue";
import { trackerSetAction } from "@/utils/vertebraSdk";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import MainTopIntroMobile from "@/components/templates/MainTopIntro/MainTopIntroMobile.vue";
import MainServiceIntroMobile from "@/components/templates/MainServiceIntro/MainServiceIntroMobile.vue";
import MainServiceCaseMobile from "@/components/templates/MainServiceCase/MainServiceCaseMobile.vue";
import MainAdvantageMobile from "@/components/templates/MainAdvantage/MainAdvantageMobile.vue";
import MainClientMobile from "@/components/templates/MainClient/MainClientMobile.vue";
import SubBannerSignupMobile from "@/components/templates/SubBannerSignup/SubBannerSignupMobile.vue";
export default defineComponent({
  components: {
    MainTopIntroMobile,
    MainServiceIntroMobile,
    MainServiceCaseMobile,
    MainAdvantageMobile,
    MainClientMobile,
    SubBannerSignupMobile
  },
  name: "MainMobile",
  setup() {
    useHead({
      link: [{ rel: "canonical", href: process.env.VUE_APP_BASE_URL }]
    });
    const store = useStore();
    const setFeatureState = (state: boolean) => {
      store.dispatch("pageCheck/featureCheck", state);
    };
    setFeatureState(true);
    onMounted(() => {
      trackerSetAction("첫 페이지");
    });
    onUnmounted(() => setFeatureState(false));
    return {
      setFeatureState
    };
  }
});
