
import { defineComponent } from "vue";
import CaseIcon01 from "@/components/atoms/icon/CaseIcon/CaseIcon01.vue";
import CaseIcon02 from "@/components/atoms/icon/CaseIcon/CaseIcon02.vue";
import CaseIcon03 from "@/components/atoms/icon/CaseIcon/CaseIcon03.vue";
import CaseIcon04 from "@/components/atoms/icon/CaseIcon/CaseIcon04.vue";
import CaseIcon05 from "@/components/atoms/icon/CaseIcon/CaseIcon05.vue";

export default defineComponent({
  components: {
    CaseIcon01,
    CaseIcon02,
    CaseIcon03,
    CaseIcon04,
    CaseIcon05
  }
});
