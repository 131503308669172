import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainTopIntroMobile = _resolveComponent("MainTopIntroMobile")!
  const _component_MainServiceIntroMobile = _resolveComponent("MainServiceIntroMobile")!
  const _component_MainServiceCaseMobile = _resolveComponent("MainServiceCaseMobile")!
  const _component_MainAdvantageMobile = _resolveComponent("MainAdvantageMobile")!
  const _component_MainClientMobile = _resolveComponent("MainClientMobile")!
  const _component_SubBannerSignupMobile = _resolveComponent("SubBannerSignupMobile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainTopIntroMobile),
    _createVNode(_component_MainServiceIntroMobile),
    _createVNode(_component_MainServiceCaseMobile),
    _createVNode(_component_MainAdvantageMobile),
    _createVNode(_component_MainClientMobile),
    _createVNode(_component_SubBannerSignupMobile)
  ], 64))
}