
import { defineComponent, ref, Ref, onUnmounted } from "vue";
import { trackerSetAction, trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import FeatureIcon01 from "@/components/atoms/icon/FeatureIcon/FeatureIcon01.vue";
import FeatureIcon02 from "@/components/atoms/icon/FeatureIcon/FeatureIcon02.vue";
import FeatureIcon03 from "@/components/atoms/icon/FeatureIcon/FeatureIcon03.vue";

export default defineComponent({
  components: {
    AppLink,
    FeatureIcon01,
    FeatureIcon02,
    FeatureIcon03
  },
  setup() {
    let itemState = ref("item1") as Ref<string>;
    let timer = 1;

    const trackEventConsultingPricing = () => {
      gtagEvents("요금제 문의하기");
      trackerSetClick("ask-pricing");
    };

    const runTimeCheck = setInterval(() => {
      timer > 2 ? (timer = 1) : timer++;
      itemState.value = `item${timer}`;
    }, 3000);

    onUnmounted(() => {
      clearInterval(runTimeCheck);
    });

    return {
      itemState,
      trackEventConsultingPricing
    };
  }
});
